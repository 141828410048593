var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-wrap"},[_c('div',{staticClass:"order-title"},[_vm._v("投诉建议")]),_c('div',{staticClass:"order-main"},[_c('a-row',[_c('a-col',{attrs:{"span":10}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"主题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'title',
                {
                  rules: [{ validator: _vm.handleFieldValidate }]
                }
              ]),expression:"[\n                'title',\n                {\n                  rules: [{ validator: handleFieldValidate }]\n                }\n              ]"}]})],1),_c('a-form-item',{attrs:{"label":"建议内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'advice',
                {
                  rules: [{ validator: _vm.handleFieldValidate }]
                }
              ]),expression:"[\n                'advice',\n                {\n                  rules: [{ validator: handleFieldValidate }]\n                }\n              ]"}],attrs:{"type":"textarea"}})],1),_c('a-form-item',{attrs:{"label":"上传文件"}},[_c('a-upload',{attrs:{"name":"file","multiple":true,"action":"/api/notice/fileUpload","headers":{
                token: _vm.token
              },"fileList":_vm.fileList},on:{"change":_vm.onUpload}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("上传文件")],1)],1)],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phone',
                {
                  rules: [{ validator: _vm.handleFieldValidate }]
                }
              ]),expression:"[\n                'phone',\n                {\n                  rules: [{ validator: handleFieldValidate }]\n                }\n              ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("提交")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }