<template>
  <div class="order-wrap">
    <div class="order-title">投诉建议</div>
    <div class="order-main">
      <a-row>
        <a-col :span="10">
          <a-form
            :form="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            @submit="handleSubmit"
          >
            <a-form-item label="主题">
              <a-input
                v-decorator="[
                  'title',
                  {
                    rules: [{ validator: handleFieldValidate }]
                  }
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="建议内容">
              <a-input
                type="textarea"
                v-decorator="[
                  'advice',
                  {
                    rules: [{ validator: handleFieldValidate }]
                  }
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item label="上传文件">
              <a-upload
                name="file"
                :multiple="true"
                action="/api/notice/fileUpload"
                :headers="{
                  token
                }"
                :fileList="fileList"
                @change="onUpload"
              >
                <a-button> <a-icon type="upload" />上传文件</a-button>
              </a-upload>
            </a-form-item>
            <a-form-item label="联系电话">
              <a-input
                v-decorator="[
                  'phone',
                  {
                    rules: [{ validator: handleFieldValidate }]
                  }
                ]"
              >
              </a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
              <a-button type="primary" html-type="submit" :loading="loading">提交</a-button>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { validate } from '@/utils/validate'
import { advise } from '@/api/notice'

export default {
  name: 'advice',
  data () {
    return {
      form: this.$form.createForm(this, { name: 'advice_form' }),
      token: this.$ls.get('Access-Token'),
      fileList: [],
      loading: false
    }
  },
  methods: {
    handleFieldValidate (rule, value, callback) {
      validate('advice', this.form.getFieldsValue(), rule.fullField).then(
        ({ valid, error }) => {
          if (valid) {
            callback()
          } else {
            callback(error)
          }
        }
      )
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            const { fileList } = this
            this.loading = true
            const params = {
              content: values.advice,
              mobile: values.phone,
              title: values.title
            }

            if (fileList.length) {
              params.attachs = fileList.map(item => {
                return {
                  fileName: item.name,
                  path: item.filePath
                }
              })
            }

            await advise([params])

            this.$message.success('投诉建议提交成功', () => {
              this.loading = false
            })
          } catch (err) {
            this.loading = false
            this.$message.error(err.message)
          }
        }
      })
    },
    onUpload (info) {
      let { fileList } = info

      if (fileList.length) {
        fileList = fileList.map(file => {
          if (file.response) {
            const { status, data } = file.response

            if (status === 'success') {
              file.name = data.fileName
              file.filePath = data.filePath
            } else {
              file.status = 'fail'
            }
          }

          return file
        })

        this.fileList = fileList
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
